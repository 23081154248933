<template>
  <div>
    <div>
      <el-button icon="el-icon-plus" size="small" type="primary" @click="addClerk">添加店员账号</el-button>
    </div>
    <el-table :data="Clerklist" header-cell-style="background-color:#E8EEF1" class="tablestyle">
      <el-table-column label="店员信息" align="center">
        <template slot-scope="scope">
          <div class="centerdiv">
            <img :src="scope.row.staff_avatar" class="tableimg"/>
            <div style="line-height:50px;margin-left:10px;">{{scope.row.nickname}}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column  label="店员姓名" prop="staff_name" align="center" ></el-table-column>
      <el-table-column  label="手机号码" prop="staff_phone" align="center"></el-table-column>
      <el-table-column  label="所属门店" align="center" v-if="storeinfo">
        <template>{{storeinfo.store_name}}</template>
      </el-table-column>
      <el-table-column  label="登录账号" prop="staff_account" align="center"></el-table-column>
      <el-table-column  label="状态" prop="state" align="center">
        <template slot-scope="scope">
          <el-tag :type="scope.row.state==1?'primary':'info'" size="small" effect="dark" style="width:68px;">{{scope.row.state==1?'启用':'禁用'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column  label="操作" align="center">
        <template slot-scope="scope">
          <el-link type="primary" style="margin-right:20px;" :underline="false" @click="$router.push({path:`/editClerk?info=${encodeURIComponent(JSON.stringify(scope.row))}&storeinfo=${encodeURIComponent(JSON.stringify(storeinfo))}`})">编辑</el-link>
          <el-link type="primary" :underline="false" @click="deleteClerk(scope.row.id)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="tablefooter">
      <Paging :total="total" :page="page" :pageNum="pageNum" @updatePageNum="updateClerkData"></Paging>
    </div>
  </div>
</template>

<script>
import Paging from '@/components/paging'
export default {
  components:{
    Paging
  },
  props:{
    storeinfo:{
      type:Object,
      default:()=>{}
    }
  },
  data:function(){
    return {
      total:0,
      page:1,
      pageNum:10,
      Clerklist:[],
    }
  },
  methods:{
    updateClerkData(val, status){
      if (status == 0) {
        this.clerkpage.pageNum = val
      } else {
        this.clerkpage.page = val
      }
      // 重新获取数据
      this.getClerkList()
    },
    getClerkList(){
      this.$axios.post(this.$api.store.storeUsersList, {
        store_id:this.storeinfo.id,
        page:this.page,
        rows:this.pageNum
      })
      .then((res) => {
        if (res.code == 0) {
          this.Clerklist = res.result.list
          this.total = res.result.total_number
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    addClerk(){
      let storeinfo = {
        id:this.storeinfo.id,
        store_name:this.storeinfo.store_name
      }
      this.$router.push({path:`/addClerk?info=${encodeURIComponent(JSON.stringify(storeinfo))}`})
    },
    deleteClerk(id){
      this.$confirm('确认要将此店员删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios.post(this.$api.store.delStoreUsers, {
          id:id
        })
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              message: '删除成功',
              type: 'success',
            });
            this.getClerkList();
          }else {
            this.$message.error(res.msg);
          }
        })
      });
    }
  }
}
</script>

<style lang="less" scoped>
.tablestyle{
  border-left:1px solid #E8EEF1;
  border-right:1px solid #E8EEF1;
  width: 100%;
  margin-top: 20px;
}
.tablefooter{
  height: 48px;
  width: 100%;
  background-color: #E8EEF1;
  display: flex;
  align-items: center;
  padding: 0 14px;
  justify-content: flex-end;
}
.tableimg{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.centerdiv{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
