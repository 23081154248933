<template>
    <div class="goodsBigBox">
        <el-form :inline="true" :model="search" class="el-form-search" label-width="100px">
            <el-form-item label="商品名称：">
                <el-input size="small" v-model.trim="search.Goodsname" placeholder="请输入商品名称/商品编码"></el-input>
            </el-form-item>
            <el-form-item label="商品分类：">
                <el-cascader v-model="search.Goodstype" size="small" :options="GoodstypeArr" class="search_input"
                    :props="{ checkStrictly: true, value: 'id', label: 'name', children: '_child', emitPath: false }"
                    filterable></el-cascader>
            </el-form-item>
            <el-form-item label=" " label-width="25px">
                <el-button size="small" type="primary" style="margin-right: 10px" @click="getGoodsList">搜索</el-button>
                <el-button size="small" type="text" @click="resetSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="Goodslist" header-cell-style="background-color:#F8F9FA" class="tablestyle"
            @selection-change="handleSelectionChange" ref="Goodslist">
            <template slot="empty">
                <No />
            </template>
            <el-table-column type="selection" label="全选" label-class-name="DisabledSelection" header-align="center"
                width="70"></el-table-column>
            <el-table-column label="商品主图" prop="goods_picture" align="center">
                <template slot-scope="scope">
                    <img :src="scope.row.goods_picture" class="tableimg" />
                </template>
            </el-table-column>
            <el-table-column label="商品名称" prop="goods_name" align="center" width="300"
                class-name="overflowstyle2"></el-table-column>
            <el-table-column label="分类名称" prop="classify_name" align="center"></el-table-column>
            <el-table-column label="商品价格" prop="goods_price" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button size="small" @click="correlation(0, scope.row)"
                        :type="scope.row.is_correlation == 1 ? 'primary' : ''" style="width: 68px">
                        {{ scope.row.is_correlation == 1 ? '已关联' : '关联' }}
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total" :page="page" :pageNum="pageNum" @updatePageNum="updateGoodsData">
            <div slot="batch">
                <el-checkbox v-model="allchecked" :indeterminate="isIndeterminate" @change="handleSelectionChangebottom"
                    style="margin-right: 20px">全选</el-checkbox>
                <el-select v-model="manyActive" size="mini" style="margin-right: 20px">
                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                </el-select>
                <el-button size="mini" @click="manyDo">确定</el-button>
            </div>
        </Paging>
    </div>
</template>

<script>
import Paging from '@/components/paging';
export default {
    components: {
        Paging,
    },
    data: function () {
        return {
            manyActive: null,
            options: [
                {
                    value: '1',
                    label: '批量关联',
                },
                {
                    value: '0',
                    label: '批量取消关联',
                },
            ],
            total: 0,
            page: 1,
            pageNum: 10,
            search: {
                Goodsname: null,
                Goodstype: '0',
            },
            GoodstypeArr: [],
            Goodslist: [],
            multipleSelection: [], //选择项
            allchecked: false, //是否全选
            isIndeterminate: false, //是否半选,
            goodsIdArr: [],
        };
    },
    methods: {
        // 修改分页配置
        updateGoodsData (val, status) {
            if (status == 0) {
                this.pageNum = val;
            } else {
                this.page = val;
            }
            // 重新获取数据
            this.getGoodsList();
        },
        // 获取商品列表接口
        getGoodsList () {
            // if (this.$route.query.id !== undefined) {
            //   this.$axios
            //     .post(this.$api.store.goodsList, {
            //       store_id: this.$route.query.id
            //     })
            //     .then((res) => {
            //       if (res.code == 0) {
            //         this.Goodslist = res.result.list
            //         this.total = res.result.total_number
            //       } else {
            //         this.$message.error(res.msg)
            //       }
            //     })
            //   return
            // }
            // console.log(111)
            let search = this.search;
            let info = JSON.parse(this.$route.query.info);
            let obj = {
                store_id: info.id,
                goods_name: search.Goodsname == '' ? null : search.Goodsname,
                classify_id: search.Goodstype == '0' ? null : search.Goodstype,
                page: this.page,
                rows: this.pageNum,
            };
            this.$axios.post(this.$api.store.goodsList, obj).then(res => {
                if (res.code == 0) {
                    this.Goodslist = res.result.list;
                    this.total = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        // 清空所有搜索条件
        resetSearch () {
            this.search = {
                Goodsname: null,
                Goodstype: '0',
            };
        },
        // 表格选中状态发生改变
        handleSelectionChange (val) {
            this.multipleSelection = val;
            this.allchecked = val.length == this.Goodslist.length;
            this.isIndeterminate = val.length > 0 && val.length < this.Goodslist.length;
        },
        // 底部全选事件
        handleSelectionChangebottom () {
            this.$refs.Goodslist.toggleAllSelection();
        },
        // 获取商品分类信息
        getGoodsType () {
            this.$axios
                .post(this.$api.goods.goodsClass, {
                    name: null,
                    is_show: null,
                })
                .then(res => {
                    if (res.code == 0) {
                        let arr = res.result;
                        arr.unshift({
                            id: '0',
                            name: '全部',
                        });
                        this.GoodstypeArr = arr;
                    }
                });
        },
        // 关联商品
        correlation (status, row) {
            let info = JSON.parse(this.$route.query.info);
            let that = this;
            let is_c = row.is_correlation;
            let co = null;
            if (status == 0) {
                co = row.is_correlation ? 0 : 1;
            } else {
                co = !row.is_correlation ? 0 : 1;
            }
            this.$axios
                .post(this.$api.store.correlationGoods, {
                    store_id: info.id,
                    goods_id: status ? that.goodsIdArr : row.id,
                    is_correlation: co,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.getGoodsList();
                        that.$message({
                            message: '修改关联状态成功',
                            type: 'success',
                        });
                    }
                });
        },
        // 多选商品进行关联相关操作
        manyDo () {
            if (this.multipleSelection.length == 0) {
                this.$message({
                    message: '请勾选要操作的每一项！！',
                    type: 'warning',
                });
                return;
            }
            if (this.manyActive === null) {
                this.$message({
                    message: '请选择要进行的操作！！',
                    type: 'warning',
                });
                return;
            }
            this.multipleSelection.map(item => {
                this.goodsIdArr.push(item.id);
            });
            this.correlation(1, { is_correlation: this.manyActive == 1 ? 1 : 0 });
        },
    },
    created () {
        this.getGoodsList();
        this.getGoodsType();
    },
};
</script>

<style lang="less" scoped>
.goodsBigBox {
    background-color: #fff;
}

.tablestyle {
    border-left: 1px solid #e8eef1;
    border-right: 1px solid #e8eef1;
    width: 100%;
    height: calc(100% - 133px) !important;

    /deep/ .el-table__body-wrapper {
        height: calc(100% - 48px);
        overflow: hidden;
        overflow-y: auto;
    }
}

.tableimg {
    width: 50px;
    height: 50px;
}

/*表格表头全选*/
.el-table /deep/.DisabledSelection .cell .el-checkbox__inner {
    margin-left: -30px;
    position: relative;
}

.el-table /deep/.DisabledSelection .cell:before {
    content: '全选';
    position: absolute;
    right: 10px;
}

/deep/.overflowstyle2 {
    .cell {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        white-space: normal !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}</style>
